<template>
    <!-- <testLotti /> -->
    <div class="like-container">
        <div class="flex items-center gap-2">
            <div class="flex items-center text-sm text-primary gap-2.5">
                <Vue3Lottie ref="lottieAnimationLike"
                    :animationData="lotties['like']"
                    :height="47" :width="47"
                    :loop="false" :autoPlay="false"
                    @click="handleLottiePlay('like')"
                />

                <Vue3Lottie
                    ref="lottieAnimationBell"
                    :animationData="lotties['bell']"
                    :height="40"
                    :width="40"
                    :loop="false"
                    :autoPlay="false"
                    @click="handleLottiePlay('bell')"
                />

                <span>{{ likeCounts }}</span>

            </div>
            <div class="flex ml-8">
                <template v-for="user in likedUsers" :key="user.id">
                    <Avatar v-if="user.avatar" size="8" :user="user" class="relative -ml-3" />
                    <!-- <div class="relative flex w-8 h-8 border border-white -ml-3 rounded-full " v-if="user.avatar"
                        :style="{ zIndex: likedUsers.length - index }">
                        <img :src="user.avatar" :alt="user.firstname" class="avatar rounded-full object-cover w-full" />
                    </div> -->
                </template>
                


                <div v-if="additionalLikes > 0" class="relative text-primary text-xs font-medium text-center justify-center items-center bg-[#E6E7F7] flex
            w-8 h-8 border border-white rounded-full -ml-3 z-[-1]" :style="{ zIndex: 0 }">
                    +{{ additionalLikes }}

                </div>


            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { ref, computed } from 'vue';
import { Vue3Lottie } from 'vue3-lottie'
import likeJSON from '@/Lotti/like.json';
import bellJSON from '@/Lotti/bell.json';
import testLotti from './testLotti.vue';
import moment from 'moment';
import Avatar from '@/Components/Avatar.vue';

export default {
    props: {
        post: Object,
        postId: {
            type: Number,
            default: 0
        },
        initialLikeCounts: {
            type: Number,
            default: 0
        },
        initialLikedUsers: Array,
        initialUserLikedType: String,
    },
    components: {
        Vue3Lottie,
        testLotti,
        Avatar
    },
    setup(props) {
        const likeTypes = [
            'like',
            //'bell',
        ];
        const lotties = {
            like: likeJSON,
            bell: bellJSON,
        };
        function isAtLeastThreeHoursOld(date) {
            const givenDate = moment(date);
            const currentDate = moment();
            const diffInHours = currentDate.diff(givenDate, 'hours');
            return diffInHours >= 3;
        }

        function xorshift(seed) {
            let x = seed ^ (seed << 11);
            x = x ^ (x >> 19);
            x = x ^ (x << 8);
            return x >>> 0; // Ensure the result is positive
        }

        function getnumber(post) {
            if(!isAtLeastThreeHoursOld(post?.created_at)){
                return 0;
            }
            const hashed = xorshift(post.id);
            const min = 20;
            const max = 60;
            const range = max - min + 1;
            const result = min + (hashed % range);

            return result;
        }

        const baseLikeCounts = getnumber(props.post)
        const likeCounts = ref(baseLikeCounts +  props.initialLikeCounts);
        const likedUsers = ref(props.initialLikedUsers);
        const userLikedType = ref(props.initialUserLikedType);

        const lottieAnimationLike = ref(null);
        const lottieAnimationBell = ref(null);
        const liked = ref(false);

        const additionalLikes = computed(() => {
            let zero = 0;
            let i;
            for (i in likedUsers.value) {
                zero += likedUsers.value[i].avatar ? 1 :  0;
            }
            return Math.max(likeCounts.value - zero, 0)
        });

        async function toggleLike(type) {
            try {
                if (userLikedType.value === type) {
                    // Unlike the post
                    const response = await axios.delete(`/posts/${props.postId}/unlike`);
                    const data = response.data;

                    if (data.success) {
                        likeCounts.value = data.likeCounts + baseLikeCounts;
                        userLikedType.value = data.userLikedType;
                        likedUsers.value = data.likedUsers;
                        liked.value = false;
                    }
                } else {
                    // Like the post with the specified type
                    const response = await axios.post(`/posts/${props.postId}/like`, { type });
                    const data = response.data;

                    if (data.success) {
                        likeCounts.value = data.likeCounts + baseLikeCounts;
                        userLikedType.value = data.userLikedType;
                        likedUsers.value = data.likedUsers;
                        liked.value = true;

                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
                // Optionally, handle errors or show a message to the user
            }
        }

        const play = (type) => {
            if (type === 'like') {
                lottieAnimationLike.value.play();
            }
            else if (type == 'bell') {
                lottieAnimationBell.value.play();
            }
        }
        function handleLottiePlay(type) {
            play(type)
            toggleLike(type);
        }


        return {
            likeTypes,
            likeCounts,
            likedUsers,
            userLikedType,
            toggleLike,
            additionalLikes,
            lotties,
            handleLottiePlay,
            lottieAnimationLike,
            lottieAnimationBell,
            play,
            liked
        };

    },
    methods: {


    }
};
</script>

<style scoped>
.like-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.like-buttons {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
}

button {
    margin-right: 10px;
    padding: 5px 10px;
}

button.liked {
    color: red;
}

.liked-users .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 5px;
}
</style>
